.bg-msecondary {
    background-color: var(--secondary) !important;
}
.bg-grey-border {
    background-color: var(--grey-border);
}
.bg-green-box {
    background-color: var(--green-box);
}
.bg-red {
    background-color: var(--red);
}
.bg-yellow-box {
    background-color: var(--yellow-box);
}
.bg-light-grey {
    background: var(--light-grey) !important;
}

.icon-active-color {
    filter: invert(43%) sepia(95%) saturate(746%) hue-rotate(160deg)
        brightness(101%) contrast(104%);
}

.navbar-active-menu {
    background: linear-gradient(
        180deg,
        #e6eeff 0%,
        rgba(230, 247, 255, 0) 100%
    );
    width: 70px;
    height: 100%;
    /* Blue */
    border-top: 2px solid #00acee;
    padding: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.navbar-menu {
    width: 70px;
    height: 100%;
    /* Blue */

    padding: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.text-h1-green-box h1 {
    color: var(--green-box);
}

.text-h1-yellow-box h1 {
    color: var(--yellow-box);
}

.text-grey {
    color: var(--grey-text-login) !important;
}
.text-error {
    color: var(--red) !important;
}
.text-black {
    color: var(--black-text) !important;
}
.text-primaryy {
    color: var(--primary) !important;
}
.text-semiblack {
    color: #494746;
}
.text-blue {
    color: #00acee;
}
.text-green {
    color: #72be42;
}
.text-orange {
    color: #fbc02a;
}
.text-red {
    color: #ff5c58;
}
.text-abu-muda {
    color: #bbbbbb;
}
.text-abu-tua {
    color: #494746;
}
.text-white {
    color: #ffffff;
}

.bg-semiblack {
    background-color: #494746;
}
.bg-lightblue {
    background: rgba(0, 172, 238, 0.06);
}
.bg-blue {
    background-color: #00acee;
}
.bg-lightorange {
    background: rgba(242, 184, 37, 0.12);
}
.bg-orange {
    background-color: #fbc02a;
}
.bg-lightred {
    background: rgba(255, 92, 88, 0.1);
}
.bg-red {
    background-color: #ff5c58;
}
.bg-abu {
    background-color: #f8f8f8;
}

.shadow-button {
    box-shadow: 0px 2px 50px rgba(93, 95, 97, 0.2);
}

.border-blue {
    border: 1px solid #00acee;
}
.border-2-blue {
    border: 2px solid #00acee;
}
.border-1-grey {
    border: 1px solid grey;
}
.border-bottom-red {
    border-bottom: 1px solid #ff5c58;
}
.border-bottom-blue {
    border-bottom: 1px solid #00acee;
}
.border-bottom-grey {
    border-bottom: 1px solid var(--grey-border);
}

.modal-container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-container Button {
    width: 90%;
    border: 1px solid #cecece;
    border-radius: 6px;
    color: #cecece;
}

.modal-container h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    margin-bottom: 5px;
    line-height: 24px;
    text-align: center;

    /* Black */

    color: #0a0a0a;
}

.modal-container p {
    margin-inline: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    /* new */

    color: #a3a3a3;
}

.status-container {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-block: 25px;
}

.status-container > * {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 10px;
    width: 64px;
    height: 54px;
    border-radius: 6px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    color: #ffffff;
}

.status-container > *:first-child {
    background-color: #72be42;
}

.status-container > *:nth-child(2) {
    background-color: #fbc02a;
}
.status-container > *:nth-child(3) {
    background: #ff5c58;
}

.status-container > *:nth-child(4) {
    background: #00acee;
}
