.home-card-wrapper {
    width: 100%;
    /* height: 90vh; */

    background: #ffffff;
    border-radius: 30px 30px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    position: relative;
    min-height: 70vh;

    /* Black */

    color: #0a0a0a;
}


 


.home-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 100%;
    height: max-content;
    margin-bottom: 20%;
}

.card-container-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-top: 50px;
    margin-bottom: 10px;
    width: 90%;
}

.bg-pattern {
    position: absolute;
    top: 0;
    right: 0;
}

@media screen and (min-width: 500px) {
    .bg-pattern {
        right: calc(50% - 250px);
    }

    
}

.absent-card-time h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;

    /* primary */
}

.absent-card-time h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    /* grey */

    color: #cecece;
}

.absent-card-date {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;

    border-radius: 6px;
}

.absent-card-date h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #ffffff;
    max-width: 35px;
    margin-bottom: 0;
}

.absent-card-date-izin {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    background: #4e4e4e;

    border-radius: #4e4e4e 6px;
}

.absent-card-date-izin h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #ffffff;
    max-width: 35px;
    margin-bottom: 0;
}

.no-border {
    border: none !important;
}

.absent-stats h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
    text-align: center;
    color: var(--primary);

    /* primary */
}

.absent-stats h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    text-align: center;

    /* grey */

    color: #cecece;
}

.absent-stats {
    max-height: 30px;
}

.absent-info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    position: absolute;
    top: -40px;
    width: 90%;
    height: 76px;
    padding-inline: 15px;
    z-index: 20;

    /* light grey */

    background: #f8f8f8;
    border-radius: 15px;
}

.peringatan-card {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;

    position: absolute;
    top: 17px;
    width: calc(90% - 2px);
    height: 36px;
    padding-inline: 15px;
    z-index: 15;
    color: white;

    /* light grey */

    background: #ff0000;
    border-radius: 0px 0px 15px 15px;

    animation: slideDown 0.5s ease-in-out;
}

@keyframes slideDown {
    from {
        top: 0px;
    }
    to {
        top: 17px;
    }
}

.absent-info-container h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;

    /* Grey - 100 */

    color: #5d5f61;
    margin-bottom: 0;
}

.absent-info-container h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
}

.date-container {
}

.absensi-button Button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* Blue */

    color: #ffffff;
    border-radius: 10px;
    background: #00acee;
    padding: 13px;
}

.absensi-button-disabled Button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* Blue */

    color: #ffffff !important;
    border-radius: 10px;
    background: #cecece;
    padding: 13px;
}

.home-user-info h1 {
    ont-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #ffffff;
    margin-bottom: 4px;
}

.home-user-info h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #ffffff;
}

.home-user-info-container {
    margin-top: -30px;
    padding-inline: 15px;
}

.home-user-location {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 25px 11px 15px;
    gap: 10px;

    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 15px;
    margin-top: 30px;
}

.home-user-location p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;

    /* white */

    color: #ffffff;
    margin-bottom: 0;
}

.notification-icon {
    position: absolute;
    top: 20px;
    right: 10px;
    margin-top: 10px;
    margin-right: 10px;
    z-index: 100;
}

.notification-count {
    background: #fbc02a;
    width: 15px;
    height: 15px;
    font-size: 10px;
    border-radius: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: absolute;
    top: -5px;
    right: 0;
}

.wrapper {
    max-width: 100%;
    margin: 0 auto;
    height: 100vh;
    overflow-x: hidden;
}


@media screen and (min-width: 500px) {
    .wrapper {
        max-width: 500px !important;
        margin: 0 auto;
       box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.25);
       height: 100vh;

    }

    .notification-icon {
    position: absolute;
    top: 20px;
    right: calc(50% - 250px);
     margin-top: 10px;
    margin-right: 10px;
    z-index: 100;
}


}
   