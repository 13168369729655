.radius-6 {
    border-radius: 6px !important;
}
.radius-8 {
    border-radius: 8px !important;
}
.radius-12 {
    border-radius: 12px !important;
}
.radius-circle {
    border-radius: 100%;
}
.elipse-2 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.w-90 {
    width: 90%;
}

.w-max-content {
    width: max-content;
}

.h-max-content {
    height: max-content;
}

.pt-55 {
    padding-top: 55px;
}
.pt-content {
    padding-top: 68px;
}
.pt-140 {
    padding-top: 140px;
}

.pb-40 {
    padding-bottom: 40px;
}
.pb-70 {
    padding-bottom: 70px;
}

.pl-0 {
    padding-left: 0px;
}

.py-8px {
    padding-block: 8px;
}
.px-16 {
    padding-inline: 16px;
}
.px-20 {
    padding-inline: 20px;
}
.py-12 {
    padding-block: 12px;
}
.py-14 {
    padding-block: 14spx;
}
.py-20 {
    padding-block: 20px;
}
.py-24 {
    padding-block: 24px;
}
.pr-18 {
    padding-right: 18px;
}

.h-50px {
    height: 50px;
}
.h-85 {
    height: 85px;
}
.h-10vh {
    height: 10vh;
}
.h-100vh {
    height: 100vh;
}
.h-full {
    height: 100%;
}
.min-h-screen {
    min-height: 100vh;
}

.w-1px {
    width: 1px;
}
.w-30 {
    width: 30%;
}
.w-35 {
    max-width: 350px;
}
.w-50 {
    width: 50%;
}
.w-65 {
    width: 65px;
}
.w-85 {
    width: 85px;
}
.w-90vw {
    width: 90vw;
}

.w-90percent {
    width: 90%;
}

.mxw-90 {
    max-width: 90%;
}

.w-full {
    width: 100%;
}

.mt--1 {
    margin-top: -1px;
}
.mt-5px {
    margin-top: 5px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-15 {
    margin-top: 15px;
}
.mt-35 {
    margin-top: 35px;
}
.mt-45 {
    margin-top: 45px;
}
.mt-55 {
    margin-top: 55px;
}
.mt-57 {
    margin-top: 57px;
}
.mt-60 {
    margin-top: 60px;
}
.mt-75 {
    margin-top: 75px;
}
.mt-80 {
    margin-top: 80px;
}
.mt-88 {
    margin-top: 88px;
}
.mt-100 {
    margin-top: 100px;
}
.mt-110 {
    margin-top: 110px;
}
.mt-120 {
    margin-top: 120px;
}
.mt-130 {
    margin-top: 130px;
}
.mt-150 {
    margin-top: 150px;
}
.mt-200 {
    margin-top: 200px;
}

.mb--3 {
    margin-bottom: -3px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-40 {
    margin-bottom: 40px;
}
.mb-60 {
    margin-bottom: 60px;
}
.mb-65 {
    margin-bottom: 65px;
}
.mb-70 {
    margin-bottom: 70px;
}
.mb-100 {
    margin-bottom: 100px;
}
.mb-120 {
    margin-bottom: 120px;
}

.mi-50px {
    margin-inline: 50px;
}

.ml-0 {
    margin-left: 0px;
}
.ml-170 {
    margin-left: 170px;
}

.mr-9 {
    margin-right: 9px;
}
.mr-10 {
    margin-right: 10px;
}

.gap-10 {
    gap: 30px;
}
.gap-15 {
    gap: 15px;
}

.overflow-hidden {
    overflow: hidden;
}

.padblock-8 {
    padding-block: 8px;
}
