.input-absensi-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-self: flex-end;

    position: absolute;
    z-index: 10;
    width: 100%;
    height: 20vh;
    bottom: 0px;
    margin-bottom: 0;
    gap: 20px;

    background: white;
}

.absensi-footer-bottom {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90%;
    gap: 15px;
}
.input-absensi-footer-Button Button {
    margin-bottom: 0px !important;
}

.input-absensi-footer-Button {
    margin-bottom: 0px !important;
    width: 90%;
    height: fit-content;
}

.absensi-input-card h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15.7025px;
    line-height: 19px;
    margin-right: 0px;
    margin-bottom: 5px;

    /* identical to box height */

    /* Black */

    color: #0a0a0a;
}

.absensi-input-card h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    /* identical to box height */

    margin-right: 0px;
    margin-bottom: -10px;

    /* new */

    color: #a3a3a3;
}

.absensi-input-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--light-grey);
    padding-inline: 15px;
    gap: 15px;
}

.input-absensi-button {
    height: 35px;
    width: 35px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--primary);
}

.input-absensi-button-container {
    width: 50px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* Grey - 100 */

    color: #5d5f61;
}

.input-absensi-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    text-align: center;
}

.button-absen-masuk {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    ont-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #ffffff;

    width: 90%;

    height: 45px;
    border: none;

    /* Blue */

    background: var(--primary);
    border-radius: 6px;

    /* Inside auto layout */
}

.formbutton2 {
    display: flex;
    align-items: center;
    align-self: flex-end;
    margin-top: 20px;
    margin-right: 50px;
    justify-content: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 89%;

    background: #95e1d3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 17px;
    width: 140px;
    height: 40px;
    /* or 21px */

    color: #28385e;
}

.formbutton2-disable {
    display: flex;
    align-items: center;
    align-self: flex-end;
    margin-top: 20px;
    margin-right: 50px;
    justify-content: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 89%;

    background: #8c8c8c;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 17px;
    width: 140px;
    height: 40px;
    border: none;

    color: #28385e;
}

.formbutton2:hover {
    cursor: pointer;
    background: #28385e;
    color: #95e1d3;
    transform: scale(1.1);
}

.question_container {
    display: flex;
    /* border: 3px red solid; */
    width: 800px;
    padding: 0px 15px;
    padding-block: 30px;
    padding-inline: 20px;
    border-radius: 25px;
    padding-left: 40px;

    background: var(--primary);
}

.map_container {
    height: 300px;
    width: 300px;
}

.take-picture-button {
    position: absolute;
    bottom: 40px !important;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100 !important;
}

.take-picture-button Button {
    width: 80px !important;
    height: 80px !important;
    border-radius: 100% !important;
    background: var(--primary) !important;
    z-index: 100 !important;
}

.change-camera-button {
    position: absolute;
    bottom: 50px !important;
    right: 40px;
    z-index: 100 !important;
}

.change-camera-button Button {
    width: 60px !important;
    height: 60px !important;
    border-radius: 100% !important;
    background: #d9d9d962 !important;
    z-index: 100 !important;
}

.change-camera-button Button img {
    width: 30px !important;
}

.change-camera-button-retake {
    position: absolute;
    bottom: 170px !important;
    right: 20px;
    z-index: 100 !important;
}

.change-camera-button-retake Button {
    width: 60px !important;
    height: 60px !important;
    border-radius: 100% !important;
    background: #d9d9d962 !important;
    z-index: 100 !important;
}

.change-camera-button-retake Button img {
    width: 30px !important;
}

.locate-button {
    position: absolute;
    bottom: 170px !important;
    right: 10px;
    z-index: 100 !important;
    background: #ffffff;
    border-radius: 6px;
}

.locate-button Button {
    width: 20px !important;
    height: 60px !important;
    background: #ffffff;
    border-radius: 6px;
    z-index: 100 !important;
}
.locate-button Button img {
    width: 25px !important;
}

.barcode-scanner video {
    height: 75vh !important;
    object-fit: cover !important;
}

.qr-background {
    background: #00000078;
    border-radius: 6px;
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 3;
    -webkit-clip-path: polygon(
        0% 0%,
        0% 100%,
        15% 100%,
        15% 25%,
        85% 25%,
        85% 65%,
        15% 65%,
        15% 100%,
        100% 100%,
        100% 0%
    );
    clip-path: polygon(
        0% 0%,
        0% 100%,
        15% 100%,
        15% 25%,
        85% 25%,
        85% 65%,
        15% 65%,
        15% 100%,
        100% 100%,
        100% 0%
    );
    top: 0px;
    text-align: center;
    color: white;

    padding: 20px;
}

.qr-background h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 89%;
    color: #ffffff;
    margin-top: 16vh;
}

.qr-background h5 {
    font-weight: 400;
    font-size: 20px;
    line-height: 89%;
    color: #ffffff;
    margin-top: 46vh;
}

.intersect1 {
    position: absolute;
    z-index: 5;
    rotate: 180deg;
    top: calc(25% - 2px);
    left: calc(15% - 2px);
}

.intersect2 {
    position: absolute;
    z-index: 5;
    rotate: 270deg;

    top: calc(25% - 2px);

    right: calc(15% - 2px);
}
.intersect3 {
    position: absolute;
    z-index: 5;
    bottom: calc(35% - 2px);
    right: calc(15% - 2px);
}
.intersect4 {
    position: absolute;
    z-index: 5;
    rotate: 90deg;

    bottom: calc(35% - 2px);
    left: calc(15% - 2px);
}

.lembur-button Button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    width: 87%;
    margin: 0 auto;
    margin-top: 20px;
    /* Blue */

    color: #ffffff;
    border-radius: 10px;
    background: #00acee;
    padding: 13px;
}
