.pdf {
    margin-left: 6px;
    margin-top: -150px;
    position: relative;
    margin-bottom: 0;
    z-index: 1;
}

.status {
    margin-top: 80px;
    position: relative;
    z-index: 2;
}

.icon-margin {
    margin-top: 10px;
    margin-left: 170px;
}
