@import url('./style.css');

@media (max-width: 800px) {
    .i-ultra-container {
        background-color: white;
    }
}

@media (max-width: 700px) {
    .i-ultra-container {
        background-color: white;
    }
}

@media (max-width: 600px) {
    .i-ultra-container {
        background-color: white;
    }
}

@media (max-width: 500px) {
    .i-ultra-container {
        background-color: white;
    }
}

@media (max-width: 400px) {
    .i-ultra-container {
        background-color: white;
    }
}

* {
    font-family: 'Inter';
}

.p-title {
    padding-block: 17px;
    padding-left: 18px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
}

.i-ultra-container {
    max-width: 500px;
    min-width: 300px;
    margin: 0 auto;
    //margin-top: -5px
}

.i-active-blue {
    color: #00acee;
    border-bottom: 4px solid #00acee;
}

.i-switch:hover {
    cursor: pointer;
}

.i-inactive-gray {
    border-bottom: 4px solid #cecece;
}

.i-card {
    background: #f8f8f8;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
}

.i-btn-bottom {
    margin-bottom: 80px;
    background-color: #00acee;
    color: white;
    padding-block: 10px;
    box-shadow: 0px 2px 50px rgba(93, 95, 97, 0.2);
    border-radius: 6px;
    width: 90%;
}

.input-state {
    min-width: 200px;
    max-width: 600px;
}

.input-dropdown {
    box-sizing: border-box;
    justify-content: center;
    padding: 14px 16px;
    height: 50px;
    border-radius: 6px;
}

.visibility-maximum {
    z-index: 10000;
    border: 2px solid black;
}

/* =============== AJUKAN IZIN  =============== */
.izin-ajukan-after-div {
    margin-top: -30px;
}

.izin-ajukan-btn {
    padding: 10px;
    box-shadow: 0px 2px 50px rgba(93, 95, 97, 0.2);
    border-radius: 6px;
}


.ajukan-btn-container{
    display: flex;
    justify-content: center;


}

@media screen and (min-width: 500px) {
.izin-ajukan-btn {
    max-width: 480px !important;
}

    
}

/* =============== AJUKAN CUTI  =============== */

.cuti-ajukan-container {
    margin-top: 120px;
}

/* =============== GAJI  =============== */
.gaji-after-title {
    margin-top: 45px;
}

.gaji-my-card-group {
    margin-top: 150px;
    margin-bottom: 100px;
}

.gaji-text-gray {
    width: 105.97px;
    height: 17px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #bbbbbb;
}

.gaji-text-black {
    width: 152px;
    height: 19px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-top: -3px;
    color: #494746;
}

.gaji-status-pelunasan {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 20px;
    gap: 10px;
    width: 100px;
    height: 27px;
    background: #fbc02a;
    border-radius: 6px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: -3px;
}

.gaji-status-pelunasan-txt {
    width: 73px;
    height: 15px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.tt {
    width: 95%;
}

.rs-picker {
    border: 0.1px solid #c5c6c7;
    border-radius: 3px;
    box-sizing: border-box;
}

.rs-stack {
    font-size: 14px !important;
}

.w-full {
    width: 100%;
}

.text-field-modifier-time {
    height: 40px !important;
    background: #fbfbfb !important;
    border: 1px solid #e6e9ed !important;
    border-radius: 5px !important;
    display: flex !important;
    justify-content: space-between !important;
    padding: 10px 10px !important;
}

.text-field-modifier-multiline {
    padding: 10px 16px;
    gap: 10px !important;
    width: 100%;
    height: 100%;
    background: #fbfbfb !important;
    border-radius: 5px !important;
    padding: 5px 10px !important;

    border: 1px solid #e6e9ed !important;
}

.pengajuan-lembur p {
    margin-bottom: 10px;
}

.text-bold {
    font-weight: 600;
}
.text-field-modifier-long {
    height: 40px !important;
    background: #fbfbfb !important;
    border: 1px solid #e6e9ed !important;
    border-radius: 5px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    padding: 10px 10px !important;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.justify-between {
    justify-content: space-between;
}

.title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline: 15px;
    max-width: 500px !important;
    margin: 0 auto !important;
}


.z-100 {
    z-index: 200 !important;
}
