.btn-simpan{
    background-color:#00acee;
}

.badge-position{
    right: 140px;
    bottom: -10px;
} 

.starter-margin{
    margin-top: 100px;
}