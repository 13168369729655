@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap);
.bg-msecondary {
    background-color: var(--secondary) !important;
}
.bg-grey-border {
    background-color: var(--grey-border);
}
.bg-green-box {
    background-color: var(--green-box);
}
.bg-red {
    background-color: var(--red);
}
.bg-yellow-box {
    background-color: var(--yellow-box);
}
.bg-light-grey {
    background: var(--light-grey) !important;
}

.icon-active-color {
    -webkit-filter: invert(43%) sepia(95%) saturate(746%) hue-rotate(160deg)
        brightness(101%) contrast(104%);
            filter: invert(43%) sepia(95%) saturate(746%) hue-rotate(160deg)
        brightness(101%) contrast(104%);
}

.navbar-active-menu {
    background: linear-gradient(
        180deg,
        #e6eeff 0%,
        rgba(230, 247, 255, 0) 100%
    );
    width: 70px;
    height: 100%;
    /* Blue */
    border-top: 2px solid #00acee;
    padding: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.navbar-menu {
    width: 70px;
    height: 100%;
    /* Blue */

    padding: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.text-h1-green-box h1 {
    color: var(--green-box);
}

.text-h1-yellow-box h1 {
    color: var(--yellow-box);
}

.text-grey {
    color: var(--grey-text-login) !important;
}
.text-error {
    color: var(--red) !important;
}
.text-black {
    color: var(--black-text) !important;
}
.text-primaryy {
    color: var(--primary) !important;
}
.text-semiblack {
    color: #494746;
}
.text-blue {
    color: #00acee;
}
.text-green {
    color: #72be42;
}
.text-orange {
    color: #fbc02a;
}
.text-red {
    color: #ff5c58;
}
.text-abu-muda {
    color: #bbbbbb;
}
.text-abu-tua {
    color: #494746;
}
.text-white {
    color: #ffffff;
}

.bg-semiblack {
    background-color: #494746;
}
.bg-lightblue {
    background: rgba(0, 172, 238, 0.06);
}
.bg-blue {
    background-color: #00acee;
}
.bg-lightorange {
    background: rgba(242, 184, 37, 0.12);
}
.bg-orange {
    background-color: #fbc02a;
}
.bg-lightred {
    background: rgba(255, 92, 88, 0.1);
}
.bg-red {
    background-color: #ff5c58;
}
.bg-abu {
    background-color: #f8f8f8;
}

.shadow-button {
    box-shadow: 0px 2px 50px rgba(93, 95, 97, 0.2);
}

.border-blue {
    border: 1px solid #00acee;
}
.border-2-blue {
    border: 2px solid #00acee;
}
.border-1-grey {
    border: 1px solid grey;
}
.border-bottom-red {
    border-bottom: 1px solid #ff5c58;
}
.border-bottom-blue {
    border-bottom: 1px solid #00acee;
}
.border-bottom-grey {
    border-bottom: 1px solid var(--grey-border);
}

.modal-container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-container Button {
    width: 90%;
    border: 1px solid #cecece;
    border-radius: 6px;
    color: #cecece;
}

.modal-container h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    margin-bottom: 5px;
    line-height: 24px;
    text-align: center;

    /* Black */

    color: #0a0a0a;
}

.modal-container p {
    margin-inline: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    /* new */

    color: #a3a3a3;
}

.status-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    margin-block: 25px;
}

.status-container > * {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    grid-gap: 10px;
    gap: 10px;
    width: 64px;
    height: 54px;
    border-radius: 6px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    color: #ffffff;
}

.status-container > *:first-child {
    background-color: #72be42;
}

.status-container > *:nth-child(2) {
    background-color: #fbc02a;
}
.status-container > *:nth-child(3) {
    background: #ff5c58;
}

.status-container > *:nth-child(4) {
    background: #00acee;
}

:root {
    --grey-text-login: #4f4f4f;
    --primary: #00acee !important;
    --primary-light: #69c8ed;
    --bold-blue: #262f56;
    --secondary: #f5f0dd !important;
    --grey-border: #bdbdbd;
    --light-grey: #f8f8f8;
    --black-text: #282828;
    --orange-badge: #4dbce9;
    --red: #ff5c58;
    --primary-hover: #5bc0e8 !important;
    --green-box: #72be42;
    --yellow-box: #fbc02a;
}
.MuiTableCell-root {
    font-family: 'Inter', sans-serif !important;
}
* {
    font-family: 'Inter', sans-serif;
    margin: 0;
}

.wrap-greet h1 {
    font-size: 32px;
    font-weight: 700;
    margin: 0;
    color: #4f4f4f;
    color: var(--grey-text-login);
}
.wrap-greet p {
    color: #4f4f4f;
    color: var(--grey-text-login);
}
.wrap-greet .btn-google {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    color: #4f4f4f;
    color: var(--grey-text-login);
}
html,
body,
#root {
    min-height: 100%;
    background: url(https://employee.alan.co.id/static/media/bg_pattern.9b1a76ff.png);
    overflow: auto !important;
    min-width: 100% !important;
}

.text-salmon {
    color: #00acee !important;
    color: var(--primary) !important;
}

.circular-white {
    color: white !important;
}

/* .bg-auth {
    background-color: var(--primary);
} */

/* LOGIN */

.container-auth {
    min-height: 100vh;
    display: block;
}
.container-auth .container-ilustration {
    width: 75%;
    background: #00acee;
    background: var(--primary);
    visibility: hidden;
    display: none;
}
.container-auth .text-hallo {
    margin-top: 50px;
    font-weight: bold;
    font-size: 32px;
    color: #4f4f4f;
    color: var(--grey-text-login);
}
.container-auth .text-desc {
    font-size: 14px;
    color: #4f4f4f;
    color: var(--grey-text-login);
    max-width: 400px;
}

.container-auth .btn-google {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    color: #4f4f4f;
    color: var(--grey-text-login);
    font-weight: 600;
    background-color: #fff;
}
.container-auth .container-or .line {
    height: 1px;
    background: #fff;
}
.container-auth .container-or p {
    font-size: 14px;
    color: #4f4f4f;
    color: var(--grey-text-login);
    top: -10px;
}
.container-auth .checkbox-text {
    font-size: 14px;
    color: #4f4f4f;
    color: var(--grey-text-login);
}
.container-auth .checkbox-text b {
    color: #00acee;
    color: var(--primary);
}
/* FORM */
.form label {
    font-weight: bold;
    color: #4f4f4f;
    color: var(--grey-text-login);
    font-size: 15px;
}
.btn-auth {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px;
    /* background-color: #fff !important; */
    /* color: var(--primary) !important; */
}
.bg-login {
    object-fit: cover;
    height: 690px;
}
.layout-container {
    padding-top: 80px;
}
.label-login {
    font-size: 16px;
    color: #4f4f4f;
    color: var(--grey-text-login);
    font-weight: 600;
}
.form-control-costum {
    padding: 16px 24px;
    height: 100%;
    border: 1px solid var(--border-login);
}
.btn-masuk {
    background-color: #00acee;
    background-color: var(--primary);
    font-size: 18px;
    color: var(--white);
    border-radius: 8px;
}
.btn-masuk:hover {
    color: var(--white);
}
.form-check {
    display: flex;
    align-items: center;
}
.form-check input {
    width: 24px;
    height: 24px;
}
.form-check label {
    margin-left: 16px;
    color: #4f4f4f;
    color: var(--grey-text-login);
}
.text-forgot {
    color: #fff;
    font-size: 14px;
}
/* .text-forgot:hover {
    color: #fff;
    text-decoration: none;
} */

/* SIDEBAR */
.menu-sidebar {
    text-decoration: none !important;
    border-radius: 8px !important;
    color: #262f56;
    color: var(--bold-blue);
}
.menu-sidebar img {
    width: 20px;
}
.menu-sidebar.active {
    background-color: #69c8ed !important;
    background-color: var(--primary-light) !important;
}
.menu-sidebar:hover {
    text-decoration: none !important;
    background-color: #69c8ed !important;
    background-color: var(--primary-light) !important;
}
.menu-sidebar .menu-text span {
    font-weight: 600;
    font-size: 15px;
}
.btn-profile:hover {
    background-color: transparent !important;
}

/* MOBILE NAV */
.menu-mobile {
    bottom: 0px;
    width: 100%;
    background-color: white;
}
.menu-mobile .item-menu {
    width: 45px;
    height: 45px;
    border-radius: 45px;
}
.menu-mobile .item-menu p {
    font-size: 10px;
    color: #282828;
    color: var(--black-text);
}
.menu-mobile .item-menu:hover {
    text-decoration: none;
}
.menu-mobile .item-menu p.active {
    color: #fff;
}
.menu-mobile .item-menu.active {
    background-color: #00acee;
    background-color: var(--primary);
}
.menu-mobile .item-menu.find-friend {
    border-radius: 45px;
    width: 45px;
    height: 45px;
    background-color: #00acee;
    background-color: var(--primary);
    margin-top: -40px;
    border: 10px solid #fff;
    box-sizing: content-box;
}
.menu-mobile .item-menu img {
    width: 24px;
}

/* PROFILE */
.card-profile .container-info {
    padding-left: 1rem;
    padding-right: 1rem;
}
.card-profile .container-info .container-row {
    text-align: center;
}
.card-profile .container-info .img-profile {
    width: 100px;
    height: 100px;
    border-radius: 100px;
}
.card-profile .container-info .btn-edit-profile {
    box-shadow: none;
    height: 35px;
    width: 100%;
}
.card-interest.profile .container-interest {
    max-height: -webkit-max-content;
    max-height: max-content;
    overflow: visible;
}
.card-interest.profile .item-interest {
    margin-bottom: 15px !important;
}
.card-interest.profile .item-interest p {
    font-size: 14px;
}
.container-interest {
    max-height: 23px;
    overflow: hidden;
}
.container-interest .item-interest {
    background-color: #f5f0dd;
    background-color: var(--secondary);
    border-radius: 4px;
}
.container-interest .item-interest p {
    font-weight: 600;
    font-size: 10px;
}
.text-forgot a {
    color: #4f4f4f;
    color: var(--grey-text-login);
    text-decoration: none;
    font-size: 14px;
}
.text-forgot a:hover {
    color: #4f4f4f;
    color: var(--grey-text-login);
    text-decoration: none;
}

/* navbar */
.img-user-dropdown {
    width: 40px;
    height: 40px;
    object-fit: cover;
}
.wrap-navbar {
    height: auto;
    background-color: var(--white);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.12);
}
a.link-custom {
    font-size: 16px;
    margin: 3px;
    padding: 8px;
}
a.link-custom.active {
    font-weight: 500;
    color: #00acee !important;
    color: var(--primary) !important;
}
a.link-custom.active:hover {
    font-weight: 500;
    color: #00acee !important;
    color: var(--primary) !important;
}
.name-user-dropdown {
    font-size: 14px;
    color: #262f56;
    color: var(--bold-blue);
}
.dropdown-toggle {
    color: #262f56;
    color: var(--bold-blue);
}
.dropdown-toggle::after {
    color: #262f56;
    color: var(--bold-blue);
}

/* card complete profile */
.btn-next {
    background-color: #00acee !important;
    background-color: var(--primary) !important;
    color: #fff !important;
    font-size: 16px;
}
.btn-back {
    background-color: #fff !important;
    color: #4f4f4f !important;
    color: var(--grey-text-login) !important;
    border: 1px solid #bdbdbd !important;
    border: 1px solid var(--grey-border) !important;
    font-size: 16px;
}
.card-complete-profile {
    margin: 32px 0;
    box-shadow: 0px 4px 20px rgba(9, 44, 76, 0.05);
    border: none !important;
}
.strepper-custome .MuiStepIcon-text {
    fill: #282828 !important;
    fill: var(--black-text) !important;
    font-weight: bold;
}
.strepper-custome .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 10px;
}
/* input file */
.wrap-border-file {
    border: 1px solid #9999;
    border-radius: 4px;
    padding: 32px;
}
._input-file {
    position: relative;
    width: 100%;
    background: #fff;
    border: 2px dashed #bdbdbd;
    border: 2px dashed var(--grey-border);
    border-radius: 4px;
}
._input-file:hover {
    cursor: pointer !important;
}
._input-file input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
}
._input-file p {
    color: #4f4f4f;
    color: var(--grey-text-login);
}
._input-file .placeholder-file {
    font-size: 14px;
    color: #4f4f4f;
    color: var(--grey-text-login);
    margin: 12px 0 0;
}
._input-file .container-preview::after {
    content: ' ';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
._input-file .img-preview {
    border-radius: 4px;
    max-height: 200px;
    min-height: 100px;
    object-fit: cover;
}
._input-file .preview-active .MuiSvgIcon-root,
._input-file .preview-active .placeholder-file {
    color: #fff !important;
}

._input-file .content {
    height: 150px;
    width: 50px;
}

/* card topic */
.wrap-desc-interest {
    padding: 16px 32px;
    background-color: #69c8ed;
    background-color: var(--primary-light);
    border-radius: 4px;
}
.wrap-desc-interest p {
    margin: 0;
    font-size: 14px;
}
.wrap-title-interest {
    margin-top: 24px;
}
.btn-interest {
    border: 1px solid #f4f4f4;
    width: 100%;
    font-size: 14px;

    background-color: #fff;
    border-radius: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.btn-interest:hover {
    background-color: rgba(240, 152, 86, 0.6);
}
.btn-interest.active {
    color: #fff;
    background-color: #00acee;
    background-color: var(--primary);
    border: none;
}

/* select input */
.css-g1d714-ValueContainer {
    padding: 10px 16px !important;
}
.css-g1d714-ValueContainer .css-1uccc91-singleValue {
    font-size: 15px;
}

.bar-custom {
    background-color: transparent !important;
    box-shadow: none !important;
    border-bottom: 1px solid #bdbdbd;
    border-bottom: 1px solid var(--grey-border);
}
.bar-custom button {
    color: #bdbdbd;
    color: var(--grey-border);
    font-weight: bold;
    padding: 19px 10px 8px;
    font-size: 18px;
}
.bar-custom button.Mui-selected {
    color: #262f56;
    color: var(--bold-blue);
    font-weight: bold;
}
.bar-custom span.PrivateTabIndicator-colorSecondary-13 {
    background-color: #262f56 !important;
    background-color: var(--bold-blue) !important;
}
.container-tab-bar .MuiTabs-flexContainer {
    justify-content: space-around;
}
.box-custom {
    margin: 24px 0 16px;
}
.card-match {
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.desc-user h6 {
    margin-bottom: 4px;
    font-weight: 600;
    color: #4f4f4f;
    color: var(--grey-text-login);
    font-size: 18px;
}
.desc-user p {
    margin-bottom: 4px;
    font-weight: 400;
    color: #4f4f4f;
    color: var(--grey-text-login);
    font-size: 14px;
}
.buttons-topic div {
    padding: 4px 12px;
    background-color: #f5f0dd;
    background-color: var(--secondary);
    margin: 4px;
    border-radius: 4px;
    font-size: 10px;
    color: #fff;
}
.btn-action {
    background-color: #00acee;
    background-color: var(--primary);
    margin: 4px;
    padding: 15px;
    border-radius: 8px;
}

.title-page {
    color: #262f56;
    color: var(--bold-blue);
    font-size: 24px;
    border-bottom: 1.5px solid #bdbdbd;
    border-bottom: 1.5px solid var(--grey-border);
    padding-bottom: 10px;
}
.bar-custom .MuiTab-root {
    text-transform: none !important;
}

/* FIND FRIEND */
.card-friend .img-profile {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    object-fit: cover;
}
.card-friend .container-content .btn-card-friend {
    font-size: 12px;
}

.card-friend .user-info {
    flex: 1 1;
    margin: 0px 0px 0px 15px;
}
.card-friend .user-info .name {
    font-size: 15px;
    color: #4f4f4f;
    color: var(--grey-text-login);
    margin-bottom: 10px;
}
.card-friend .user-info .subtext {
    font-size: 12px;
    color: #4f4f4f;
    color: var(--grey-text-login);
    margin-bottom: 4px;
}
.card-friend .container-profile {
    width: 50px;
    height: 50px;
}
.card-suggest-topic .title {
    font-size: 14px;
    font-weight: 500;
    color: #282828;
    color: var(--black-text);
}
.card-suggest-topic .subtitle {
    font-size: 10px;
    font-weight: 500;
    color: #4f4f4f;
    color: var(--grey-text-login);
}
.card-suggest-topic .btn-add {
    width: 35px;
    height: 35px;
}
.card-suggest-topic .btn-add {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 8px;
    background: #00acee;
    background: var(--primary);
    color: #fff;
}
.title-side-section {
    font-weight: bold;
    font-size: 18px;
    color: #282828;
    color: var(--black-text);
}
.container-suggest {
    display: none;
}
.btn-card {
    width: 45px;
    height: 45px;
    padding: 0px;
    background: #fff;
    transition: 0.2s;
}
.btn-card.btn-unfollow {
    border: 1px solid #ff5c58;
    border: 1px solid var(--red);
}
.btn-card.btn-unfollow:hover {
    background-color: #ffe4e4;
}
.btn-card.btn-chat {
    background-color: #00acee;
    background-color: var(--primary);
    border: none;
}
.btn-card.btn-chat:hover {
    background-color: #00acee;
    background-color: var(--primary);
}
.border-error {
    border: 1px solid #ff5c58 !important;
    border: 1px solid var(--red) !important;
}
.btn-outline-error {
    background-color: #fff;
}
.btn-outline-error:hover {
    background-color: #ffe4e4 !important;
}

/* CARD FRIEND SUGGEST */
.card-friend-suggest .img-profile {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    object-fit: cover;
}
.card-friend-suggest .user-info .name {
    font-size: 14px;
    color: #4f4f4f;
    color: var(--grey-text-login);
    margin-bottom: 10px;
}
.card-friend-suggest .user-info .subtext {
    font-size: 12px;
    color: #4f4f4f;
    color: var(--grey-text-login);
    margin-bottom: 4px;
}
.card-friend-suggest .container-profile {
    width: 40px;
    height: 40px;
}
.card-friend-suggest button {
    font-size: 10px;
}

/* MESSAGE */
.card-chat {
    width: 90vw;
    margin-top: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
}

.color-white {
    color: #fff !important;
}

.color-white .nama {
    color: #fff !important;
}

.color-white .message {
    color: #fff !important;
}

.color-white .waktu {
    color: #fff !important;
}

.background-primary {
    background-color: #00acee !important;
    background-color: var(--primary) !important;
}

.background-white {
    background-color: #fff !important;
}

.card-chat .profile {
    width: 30px;
    height: 30px;

    background: rgba(242, 184, 37, 0.2);
    border-radius: 50px;
    justify-self: flex-start !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-chat img {
    -webkit-filter: invert(80%) sepia(9%) saturate(6993%) hue-rotate(340deg)
        brightness(115%) contrast(97%);
            filter: invert(80%) sepia(9%) saturate(6993%) hue-rotate(340deg)
        brightness(115%) contrast(97%);
    width: 15px;
    height: 15px;
}

.card-chat .content .nama {
    font-size: 16px;
    font-weight: bold;
    color: #282828;
    color: var(--black-text);
    margin-top: -5px;
    margin-bottom: 5px;
}
.card-chat .content .waktu {
    font-size: 12px;
    color: #4f4f4f;
    color: var(--grey-text-login);
    margin: 0px;
    align-self: flex-end;
    justify-self: flex-end;
    text-align: end;
}
.card-chat .content .message {
    font-size: 14px;
    color: #4f4f4f;
    color: var(--grey-text-login);
    margin: 0px;
    max-width: 95%;
    word-break: break-all;
}

.content {
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    padding-right: 10px;
}

/* HOME */
.card-home .title {
    font-size: 20px;
    color: #282828;
    color: var(--black-text);
}
.card-home .more {
    font-size: 16px;
    color: #00acee;
    color: var(--primary);
}
.container-home {
    margin-top: 50px;
}
.container-home .container-content {
    max-width: 450px;
}
.container-home .title {
    font-size: 18px;
}
.container-home .subtitle {
    max-width: 500px;
    font-size: 12px;
    margin: auto;
}
/* CONFERENCE */
.card-conference {
    flex-direction: column;
    align-items: flex-end;
}
.card-conference .title {
    color: #282828;
    color: var(--black-text);
    font-size: 18px;
    margin-bottom: 8px;
}
.card-conference .subtitle {
    color: #282828;
    color: var(--black-text);
    font-size: 15px;
    margin-bottom: 8px;
}
.btn-create-group {
    background-color: #00acee !important;
    background-color: var(--primary) !important;
    border: none !important;
    font-weight: bold !important;
}
.btn-create-group:hover {
    background-color: #5bc0e8 !important;
    background-color: var(--primary-hover) !important;
}
hr {
    background-color: #bdbdbd;
    background-color: var(--grey-border);
}
.btn-participant {
    background-color: #00acee !important;
    background-color: var(--primary) !important;
    border: none !important;
    font-weight: 600 !important;
    font-size: 13px !important;
}
.btn-participant:hover {
    background-color: #5bc0e8 !important;
    background-color: var(--primary-hover) !important;
}

/* chat */
.content-chat {
    background-color: #fbf7f1;
    padding: 12px 24px 20px 24px;
    border-radius: 8px 8px 0 8px;
    min-width: 294px;
}
.content-chat p,
.send-chat p {
    word-break: break-all;
    white-space: pre-wrap;
    line-height: 1.4rem;
}
.content-chat,
.send-chat {
    line-height: 28px;
    max-width: 90%;
    position: relative;
}
.content-chat span {
    color: #4f4f4f;
    color: var(--grey-text-login);
    font-size: 10px;
    bottom: 0;
    right: 12px;
    position: absolute;
}
.send-chat {
    background-color: #00acee;
    background-color: var(--primary);
    padding: 12px 24px 20px 24px;
    border-radius: 8px 8px 8px 0;
    min-width: 294px;
}
.send-chat p {
    color: #fff;
    font-size: 14px;
}
.send-chat span {
    display: flex;
    flex-flow: column-reverse;
    color: #4f4f4f;
    color: var(--grey-text-login);
    font-size: 10px;
    bottom: 0;
    right: 12px;
    position: absolute;
}
.btn-title {
    border: none;
    background-color: #fff;
    padding: 0;
}

/* Modal Dialog */
img.img-trash {
    background-color: #69c8ed;
    background-color: var(--primary-light);
    border-radius: 50px;
    padding: 22px;
    margin: auto;
    display: flex;
}

/* badge notif */
span.badge-custom {
    background-color: #4dbce9;
    background-color: var(--orange-badge);
    color: #fff;
    border-radius: 30px;
}
/* NOT FOUND */
.container-not-found img {
    max-width: 500px;
}
.container-not-found .btn-back {
    background-color: #00acee !important;
    background-color: var(--primary) !important;
    font-size: 13px;
}
.btn-confirm-no {
    background-color: #00acee;
    background-color: var(--primary);
    color: #fff;
    border: none;
    padding: 14px 54px;
    font-size: 14px;
    border-radius: 8px;
}
.btn-confirm-no:hover {
    background-color: #5bc0e8;
    background-color: var(--primary-hover);
}
.btn-confirm-yes {
    background-color: #fff;
    border: 1px solid #ff5c58;
    border: 1px solid var(--red);
    color: #ff5c58;
    color: var(--red);
    padding: 14px 54px;
    font-size: 14px;
    border-radius: 8px;
}
.btn-edit-no {
    background-color: #fff;
    border: 1px solid #00acee;
    border: 1px solid var(--primary);
    color: #00acee;
    color: var(--primary);
    padding: 14px 54px;
    font-size: 14px;
    border-radius: 8px;
}
.btn-edit-no:hover {
    border: 1px solid #5bc0e8;
    border: 1px solid var(--primary-hover);
}
.wrap-check-email {
    min-height: 100vh;
}
/* Sweet alert */
.swal2-container {
    z-index: 999999 !important;
}
img.img-profile {
    object-fit: cover;
}

/* DETAIL CHAT */
.container.container-detail-chat {
    height: 100%;
}
.container-detail-chat .card-detail-chat {
    padding: 0px 0px 45px 0px;
}
.container-detail-chat .card-detail-chat .header {
    padding: 20px 32px;
    border-bottom: 1px solid #bdbdbd;
}
.container-detail-chat .card-detail-chat .chat-area,
.container-detail-chat .card-detail-chat .form-chat {
    padding: 0px 32px;
}
.container-detail-chat .card-detail-chat .profile {
    width: 50px;
    height: 50px;
    object-fit: cover;
}
.container-detail-chat .card-detail-chat .nama {
    font-size: 15px;
    color: #4f4f4f;
}
.container-detail-chat .card-detail-chat .usernama {
    font-size: 12px;
    color: #4f4f4f;
}
.flex-1 {
    flex: 1 1;
}
.detail-chat-show {
    display: none;
}
.group-detail {
    transition: 0.3s;
    display: none;
}
.group-detail .thumbnail {
    max-height: 250px;
    object-fit: cover;
}
.group-detail.show-group-detail {
    display: block;
}
/* width */
::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    background: var(--grey-border);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* NOTIF */
.container-modal-notif {
    max-width: 450px;
}
.container-modal-notif .btn-notif {
    width: 100%;
    border: none;
    font-size: 14px;
    color: #262f56;
    color: var(--bold-blue);
    background: #fff;
    border-top: 1px solid #bdbdbd;
    font-weight: 600;
    transition: 0.3s;
}
.container-modal-notif .btn-notif:hover {
    background-color: #ebeaea;
}
.modal-notif .MuiList-padding {
    padding: 0px;
}
.container-modal-notif .container-content {
    padding: 0px 20px 0px 20px;
}
.container-modal-notif .container-content p {
    font-size: 14px;
}
.container-modal-notif .container-content .item-notif .img-profile {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 40px;
}
.card-notif .img-profile {
    border-radius: 60px;
    width: 60px;
    height: 60px;
}
.card-notif .message {
    font-size: 15px;
}
.card-notif .time {
    font-size: 12px;
    bottom: 5px;
    right: 12px;
    position: absolute;
}
.cursor-pointer {
    cursor: pointer;
}
.w-30 {
    width: 30%;
}
/* Zoom */
#zmmtg-root {
    display: none;
}

#zmmtg-root #wc-content {
    overflow: visible !important;
}

#zmmtg-root #wc-content #wc-footer {
    bottom: 0 !important;
}
input[type='text']:focus.MuiInputBase-input {
    outline: none !important;
    outline-offset: none !important;
}
/* CARD INPUT USER GROUP */
.container-select-user {
    max-height: 300px;
    overflow: auto;
}
.card-input-group-user:hover {
    background-color: #69c8ed;
    background-color: var(--primary-light);
    cursor: pointer;
}
.card-input-group-user.active {
    background-color: #69c8ed;
    background-color: var(--primary-light);
}
.card-input-group-user .img-profile {
    width: 55px;
    height: 55px;
    border-radius: 55px;
    object-fit: cover;
}
.btn-del-interest {
    width: 25px;
    height: 25px;
    position: absolute;
    top: -8px;
    right: -8px;
    border-radius: 25px;
    border: 1px solid #ff5c58;
    border: 1px solid var(--red);
    background: #fff;
}

/* BTN FRIEND */
.btn-menu-friend {
    border-radius: 50px !important;
    background-color: #fff !important;
    color: #bdbdbd;
    color: var(--grey-border);
    transition: 0.3s;
    flex-direction: column;
    margin: 0 8px !important;
}
.btn-menu-friend .btn-icon {
    display: none;
    margin-right: 2px;
}
.btn-menu-friend p {
    font-size: 12px;
}
.btn-menu-friend.active {
    background-color: #00acee !important;
    background-color: var(--primary) !important;
    color: #fff;
}
.rounded-silinder {
    border-radius: 50px !important;
}
.container-stepper {
    background: #ebeaea;
    height: 10px;
    border-radius: 10px;
}
.content-stepper {
    height: 10px;
    background: #00acee;
    background: var(--primary);
    border-radius: 10px;
    width: 10%;
}
.content-stepper.step-one {
    width: 33.4%;
}
.content-stepper.step-two {
    width: 66.8%;
}
.content-stepper.step-three {
    width: 100%;
}
.text-helper {
    font-size: 12px;
    color: #4f4f4f;
    color: var(--grey-text-login);
}
.complete-profile .btn-full {
    width: 100%;
}
.complete-profile .btn-half {
    width: 48%;
}
.container-btn {
    justify-content: center;
}
div input[type='text']:focus {
    outline: 0px solid transparent !important;
    width: 100% !important;
}
.container-input-file {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    padding: 11px 16px;
}
.container-input-file.preview {
    padding: 0px 16px;
}
.text-notfound {
    display: flex;
    min-height: 60vh;
    justify-content: center;
    align-items: center;
}
/* LOADING HOME */
.container-loading {
    width: 272px;
}
#teks-loading {
    width: 242px;
    height: 24px;
    -webkit-animation-name: loadingHome;
            animation-name: loadingHome;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    overflow: hidden;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(0, 0.25, 0.35, 0.75, 1);
            animation-timing-function: cubic-bezier(0, 0.25, 0.35, 0.75, 1);
    text-align: left;
}
@-webkit-keyframes loadingHome {
    0% {
        width: 242px;
    }
    10% {
        width: 242px;
    }
    35% {
        width: 253px;
    }
    75% {
        width: 260px;
    }
    100% {
        width: -webkit-max-content;
        width: max-content;
    }
}
@keyframes loadingHome {
    0% {
        width: 242px;
    }
    10% {
        width: 242px;
    }
    35% {
        width: 253px;
    }
    75% {
        width: 260px;
    }
    100% {
        width: -webkit-max-content;
        width: max-content;
    }
}
.find-a-buddy img {
    width: 180px;
    height: 180px;
    border-radius: 180px;
}
.find-a-buddy .name {
    font-size: 20px;
}
.find-a-buddy .university {
    font-size: 12px;
}
.find-a-buddy img {
    object-fit: cover;
}
.container-matched {
    margin-right: 20px;
    margin-top: 2.5rem;
}
.container-matched img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border: 3px solid #00acee;
    border: 3px solid var(--primary);
    background: #fff;
    margin-right: -20px;
}
.chat-conference {
    color: #fff;
    background: #00acee;
    background: var(--primary);
    padding: 4px 20px;
    border-radius: 25px;
}
.chat-conference:hover {
    color: #fff;
    background-color: #5bc0e8;
    background-color: var(--primary-hover);
    text-decoration: none;
}
/* Header */
.container.container-menu {
    width: -webkit-max-content;
    width: max-content;
}
.container.container-menu a {
    color: #fff;
    transition: 0.3s;
}
.container.container-menu a.active {
    color: #fff;
    font-weight: bold;
}
.container.container-menu a:hover {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
}

::selection {
    color: #ffffff !important;
    background: #5bc0e8 !important;
    background: var(--primary-hover) !important;
}

.rounded-6 {
    border-radius: 6px;
}
.rounded-8 {
    border-radius: 8px;
}
.rounded-10 {
    border-radius: 10px;
}

.il-success-find {
    width: 300px;
}

.il-search-load {
    width: 100%;
}

.swal2-styled.swal2-default-outline:focus {
    box-shadow: none !important;
}

footer {
    background-color: #00acee;
    background-color: var(--primary);
}

footer h3 {
    font-size: 20px;
}

footer .about-app {
    font-size: 14px;
    color: #282828;
    color: var(--black-text);
}

footer .border-top {
    border-color: #fff !important;
}

footer .copy {
    font-size: 14px;
    color: #282828;
    color: var(--black-text);
    font-weight: 500;
}
footer .item-footer-link {
    background: rgba(252, 246, 236, 0.89);
    width: 35px;
    height: 35px;
    border-radius: 100%;
}
.input-avatar {
    width: 60px;
    height: 60px;
    border-radius: 60px;
    object-fit: cover;
}
.grid-avatar {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: 'area area';
}
.item-avatar {
    flex: 1 1;
}
.item-avatar img {
    width: 80px;
    height: 80px;
    border-radius: 80px;
    cursor: pointer;
    box-sizing: border-box;
    border: 4px solid #fff;
    transition: 0.2s;
}
.item-avatar.active img {
    border: 4px solid #00acee;
    border: 4px solid var(--primary);
}
.item-avatar img:hover {
    border: 4px solid #00acee;
    border: 4px solid var(--primary);
}
.item-avatar h6 {
    font-size: 14px;
}
.img-buddy {
    z-index: 10;
}
.btn_buddy {
    background-color: #00acee !important;
    background-color: var(--primary) !important;
    color: #fff !important;
}
.btn_buddy:hover {
    background-color: #5bc0e8 !important;
    background-color: var(--primary-hover) !important;
}
.buddy-brand {
    width: 60px;
    height: auto;
    object-fit: cover;
}
.skeleteon-wave {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
a.link-hover {
    color: #00acee !important;
    color: var(--primary) !important;
}

.UHIMap-map-container {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 0;
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:95px;
	right:15px;
	background-color:#00acee;
	background-color:var(--primary);
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:10px;
}
@media (max-width: 576px) {
    .il-search-load {
        width: 300px;
    }
}

@media only screen and (min-width: 500px) {
    .grid-avatar {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-template-areas: 'area area area';
    }
}

@media (min-width: 576px) {
    .il-search-load {
        width: 300px;
    }
    footer h3 {
        font-size: 25px;
    }
    .grid-avatar {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-template-areas: 'area area area area';
    }
}

@media only screen and (min-width: 768px) {
    .container-auth {
        display: flex;
    }
    .container-auth .container-ilustration {
        visibility: visible;
        display: flex;
    }
    .container-auth .container-ilustration .wrap-content {
        text-align: center;
        margin-top: 100px;
    }

    .container-auth .text-hallo {
        margin-top: 80px !important;
    }
    .card-profile .container-info {
        padding-left: 45px !important;
        padding-right: 45px !important;
    }
    .container-suggest {
        display: block !important;
    }
    .complete-profile .btn-full,
    .complete-profile .btn-half {
        width: -webkit-max-content !important;
        width: max-content !important;
    }
    .container-btn {
        justify-content: flex-end !important;
    }
    .btn-menu-friend .btn-icon {
        display: block !important;
    }
    .btn-menu-friend p {
        font-size: 15px !important;
    }
    .btn-interest {
        font-size: 16px !important;
    }
    .card-profile .container-info .container-row {
        text-align: left !important;
    }
    .container-home .title {
        font-size: 25px !important;
    }
    .container-home .subtitle {
        font-size: 14px !important;
    }
    .il-search-load {
        width: 300px;
    }
    .item-avatar h6 {
        font-size: 16px;
    }
    .item-avatar img {
        width: 90px;
        height: 90px;
        border-radius: 90px;
    }
    .grid-avatar {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
    }
    .skeleteon-wave {
        align-items: unset;
        justify-content: unset;
    }
}

@media only screen and (min-width: 500px) {
     .menu-mobile {
        max-width: 500px;
    }
}

@media only screen and (min-width: 992px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 960px) {
    .menu-mobile {
    }
    .card-profile .container-info .img-profile {
        width: 120px !important;
        height: 120px !important;
        border-radius: 120px !important;
    }
    .card-chat .profile {
        width: 60px !important;
        height: 60px !important;
        border-radius: 60px !important;
    }
    .container-detail-chat .card-detail-chat {
        padding: 0px !important;
    }
    .container.container-detail-chat {
        height: 88% !important;
    }
    .container-detail-chat .card-detail-chat .profile {
        width: 50px !important;
        height: 50px !important;
    }
    .detail-chat-show {
        display: block !important;
    }
    .card-friend .container-profile {
        width: 80px !important;
        height: 80px !important;
    }
    .card-friend .img-profile {
        width: 80px !important;
        height: 80px !important;
        border-radius: 80px !important;
    }
    .card-friend .container-content {
        flex-direction: row !important;
        align-items: center !important;
    }
    .card-friend .user-info .name {
        font-size: 18px !important;
    }
    .card-friend .user-info .subtext {
        font-size: 14px !important;
    }
    .card-friend .container-content .btn-card-friend {
        font-size: 15px !important;
    }
    .card-conference {
        flex-direction: row !important;
        align-items: center !important;
    }
    .container-home .title {
        font-size: 30px !important;
    }
    .container-home .subtitle {
        font-size: 16px !important;
    }
    .il-search-load {
        width: 450px;
    }
    .container-home {
        margin-top: 60px !important;
    }
}

@media only screen and (min-width: 1024px) {
    .container-home .title {
        font-size: 35px !important;
    }
}

@media only screen and (min-width: 1440px) {
}

@media only screen and (min-width: 1015px) {
}

@media only screen and (min-width: 1045px) {
}

@media only screen and (min-width: 1280px) {
}

@media only screen and (min-width: 1356px) {
}

/* Max Width */
@media only screen and (max-width: 959.9px) {
    .container.container-detail-chat {
        padding: 0px;
        padding-bottom: 61px;
    }
}

@media only screen and (max-width: 959px) {
    .btn-logout-sm {
        border: 1px solid #eb5757;
        width: 100%;
        padding: 10px 0;
        border-radius: 20px;
        color: #eb5757;
        font-weight: bold;
        margin-bottom: 60px;
    }
}

.radius-6 {
    border-radius: 6px !important;
}
.radius-8 {
    border-radius: 8px !important;
}
.radius-12 {
    border-radius: 12px !important;
}
.radius-circle {
    border-radius: 100%;
}
.elipse-2 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.w-90 {
    width: 90%;
}

.w-max-content {
    width: -webkit-max-content;
    width: max-content;
}

.h-max-content {
    height: -webkit-max-content;
    height: max-content;
}

.pt-55 {
    padding-top: 55px;
}
.pt-content {
    padding-top: 68px;
}
.pt-140 {
    padding-top: 140px;
}

.pb-40 {
    padding-bottom: 40px;
}
.pb-70 {
    padding-bottom: 70px;
}

.pl-0 {
    padding-left: 0px;
}

.py-8px {
    padding-block: 8px;
}
.px-16 {
    padding-inline: 16px;
}
.px-20 {
    padding-inline: 20px;
}
.py-12 {
    padding-block: 12px;
}
.py-14 {
    padding-block: 14spx;
}
.py-20 {
    padding-block: 20px;
}
.py-24 {
    padding-block: 24px;
}
.pr-18 {
    padding-right: 18px;
}

.h-50px {
    height: 50px;
}
.h-85 {
    height: 85px;
}
.h-10vh {
    height: 10vh;
}
.h-100vh {
    height: 100vh;
}
.h-full {
    height: 100%;
}
.min-h-screen {
    min-height: 100vh;
}

.w-1px {
    width: 1px;
}
.w-30 {
    width: 30%;
}
.w-35 {
    max-width: 350px;
}
.w-50 {
    width: 50%;
}
.w-65 {
    width: 65px;
}
.w-85 {
    width: 85px;
}
.w-90vw {
    width: 90vw;
}

.w-90percent {
    width: 90%;
}

.mxw-90 {
    max-width: 90%;
}

.w-full {
    width: 100%;
}

.mt--1 {
    margin-top: -1px;
}
.mt-5px {
    margin-top: 5px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-15 {
    margin-top: 15px;
}
.mt-35 {
    margin-top: 35px;
}
.mt-45 {
    margin-top: 45px;
}
.mt-55 {
    margin-top: 55px;
}
.mt-57 {
    margin-top: 57px;
}
.mt-60 {
    margin-top: 60px;
}
.mt-75 {
    margin-top: 75px;
}
.mt-80 {
    margin-top: 80px;
}
.mt-88 {
    margin-top: 88px;
}
.mt-100 {
    margin-top: 100px;
}
.mt-110 {
    margin-top: 110px;
}
.mt-120 {
    margin-top: 120px;
}
.mt-130 {
    margin-top: 130px;
}
.mt-150 {
    margin-top: 150px;
}
.mt-200 {
    margin-top: 200px;
}

.mb--3 {
    margin-bottom: -3px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-40 {
    margin-bottom: 40px;
}
.mb-60 {
    margin-bottom: 60px;
}
.mb-65 {
    margin-bottom: 65px;
}
.mb-70 {
    margin-bottom: 70px;
}
.mb-100 {
    margin-bottom: 100px;
}
.mb-120 {
    margin-bottom: 120px;
}

.mi-50px {
    margin-inline: 50px;
}

.ml-0 {
    margin-left: 0px;
}
.ml-170 {
    margin-left: 170px;
}

.mr-9 {
    margin-right: 9px;
}
.mr-10 {
    margin-right: 10px;
}

.gap-10 {
    grid-gap: 30px;
    gap: 30px;
}
.gap-15 {
    grid-gap: 15px;
    gap: 15px;
}

.overflow-hidden {
    overflow: hidden;
}

.padblock-8 {
    padding-block: 8px;
}

.fs-8 {
    font-size: 8px;
}
.fs-9 {
    font-size: 9px;
}
.fs-10 {
    font-size: 10px;
}
.fs-11 {
    font-size: 11px;
}
.fs-12 {
    font-size: 12px;
}
.fs-13 {
    font-size: 13px;
}
.fs-14 {
    font-size: 14px;
}
.fs-15 {
    font-size: 15px;
}
.fs-16 {
    font-size: 16px;
}
.fs-17 {
    font-size: 17px;
}
.fs-18 {
    font-size: 18px;
}
.fs-19 {
    font-size: 19px;
}
.fs-20 {
    font-size: 20px;
}
.fs-21 {
    font-size: 21px;
}
.fs-22 {
    font-size: 22px;
}
.fs-23 {
    font-size: 23px;
}
.fs-24 {
    font-size: 24px;
}
.fs-25 {
    font-size: 25px;
}
.fs-26 {
    font-size: 26px;
}
.fs-27 {
    font-size: 27px;
}
.fs-28 {
    font-size: 28px;
}
.fs-29 {
    font-size: 29px;
}
.fs-30 {
    font-size: 30px;
}

.font-weight-light {
    font-weight: 300;
}
.font-weight-normal {
    font-weight: 400;
}
.font-weight-medium {
    font-weight: 500;
}
.font-weight-semibold {
    font-weight: 600;
}
.font-weight-bold {
    font-weight: 700;
}
.break-word {
    word-wrap: break-word;
}

.home-card-wrapper {
    width: 100%;
    /* height: 90vh; */

    background: #ffffff;
    border-radius: 30px 30px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    position: relative;
    min-height: 70vh;

    /* Black */

    color: #0a0a0a;
}


 


.home-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 15px;
    gap: 15px;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: 20%;
}

.card-container-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-top: 50px;
    margin-bottom: 10px;
    width: 90%;
}

.bg-pattern {
    position: absolute;
    top: 0;
    right: 0;
}

@media screen and (min-width: 500px) {
    .bg-pattern {
        right: calc(50% - 250px);
    }

    
}

.absent-card-time h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;

    /* primary */
}

.absent-card-time h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    /* grey */

    color: #cecece;
}

.absent-card-date {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;

    border-radius: 6px;
}

.absent-card-date h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #ffffff;
    max-width: 35px;
    margin-bottom: 0;
}

.absent-card-date-izin {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    background: #4e4e4e;

    border-radius: #4e4e4e 6px;
}

.absent-card-date-izin h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #ffffff;
    max-width: 35px;
    margin-bottom: 0;
}

.no-border {
    border: none !important;
}

.absent-stats h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
    text-align: center;
    color: var(--primary);

    /* primary */
}

.absent-stats h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    text-align: center;

    /* grey */

    color: #cecece;
}

.absent-stats {
    max-height: 30px;
}

.absent-info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;

    position: absolute;
    top: -40px;
    width: 90%;
    height: 76px;
    padding-inline: 15px;
    z-index: 20;

    /* light grey */

    background: #f8f8f8;
    border-radius: 15px;
}

.peringatan-card {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    grid-gap: 10px;
    gap: 10px;

    position: absolute;
    top: 17px;
    width: calc(90% - 2px);
    height: 36px;
    padding-inline: 15px;
    z-index: 15;
    color: white;

    /* light grey */

    background: #ff0000;
    border-radius: 0px 0px 15px 15px;

    -webkit-animation: slideDown 0.5s ease-in-out;

            animation: slideDown 0.5s ease-in-out;
}

@-webkit-keyframes slideDown {
    from {
        top: 0px;
    }
    to {
        top: 17px;
    }
}

@keyframes slideDown {
    from {
        top: 0px;
    }
    to {
        top: 17px;
    }
}

.absent-info-container h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;

    /* Grey - 100 */

    color: #5d5f61;
    margin-bottom: 0;
}

.absent-info-container h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
}

.date-container {
}

.absensi-button Button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* Blue */

    color: #ffffff;
    border-radius: 10px;
    background: #00acee;
    padding: 13px;
}

.absensi-button-disabled Button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* Blue */

    color: #ffffff !important;
    border-radius: 10px;
    background: #cecece;
    padding: 13px;
}

.home-user-info h1 {
    ont-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #ffffff;
    margin-bottom: 4px;
}

.home-user-info h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #ffffff;
}

.home-user-info-container {
    margin-top: -30px;
    padding-inline: 15px;
}

.home-user-location {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 25px 11px 15px;
    grid-gap: 10px;
    gap: 10px;

    background: rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 15px;
    margin-top: 30px;
}

.home-user-location p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;

    /* white */

    color: #ffffff;
    margin-bottom: 0;
}

.notification-icon {
    position: absolute;
    top: 20px;
    right: 10px;
    margin-top: 10px;
    margin-right: 10px;
    z-index: 100;
}

.notification-count {
    background: #fbc02a;
    width: 15px;
    height: 15px;
    font-size: 10px;
    border-radius: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: absolute;
    top: -5px;
    right: 0;
}

.wrapper {
    max-width: 100%;
    margin: 0 auto;
    height: 100vh;
    overflow-x: hidden;
}


@media screen and (min-width: 500px) {
    .wrapper {
        max-width: 500px !important;
        margin: 0 auto;
       box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.25);
       height: 100vh;

    }

    .notification-icon {
    position: absolute;
    top: 20px;
    right: calc(50% - 250px);
     margin-top: 10px;
    margin-right: 10px;
    z-index: 100;
}


}
   
.pdf {
    margin-left: 6px;
    margin-top: -150px;
    position: relative;
    margin-bottom: 0;
    z-index: 1;
}

.status {
    margin-top: 80px;
    position: relative;
    z-index: 2;
}

.icon-margin {
    margin-top: 10px;
    margin-left: 170px;
}

@media (max-width: 800px) {
    .i-ultra-container {
        background-color: white;
    }
}

@media (max-width: 700px) {
    .i-ultra-container {
        background-color: white;
    }
}

@media (max-width: 600px) {
    .i-ultra-container {
        background-color: white;
    }
}

@media (max-width: 500px) {
    .i-ultra-container {
        background-color: white;
    }
}

@media (max-width: 400px) {
    .i-ultra-container {
        background-color: white;
    }
}

* {
    font-family: 'Inter';
}

.p-title {
    padding-block: 17px;
    padding-left: 18px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
}

.i-ultra-container {
    max-width: 500px;
    min-width: 300px;
    margin: 0 auto;
    //margin-top: -5px
}

.i-active-blue {
    color: #00acee;
    border-bottom: 4px solid #00acee;
}

.i-switch:hover {
    cursor: pointer;
}

.i-inactive-gray {
    border-bottom: 4px solid #cecece;
}

.i-card {
    background: #f8f8f8;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
}

.i-btn-bottom {
    margin-bottom: 80px;
    background-color: #00acee;
    color: white;
    padding-block: 10px;
    box-shadow: 0px 2px 50px rgba(93, 95, 97, 0.2);
    border-radius: 6px;
    width: 90%;
}

.input-state {
    min-width: 200px;
    max-width: 600px;
}

.input-dropdown {
    box-sizing: border-box;
    justify-content: center;
    padding: 14px 16px;
    height: 50px;
    border-radius: 6px;
}

.visibility-maximum {
    z-index: 10000;
    border: 2px solid black;
}

/* =============== AJUKAN IZIN  =============== */
.izin-ajukan-after-div {
    margin-top: -30px;
}

.izin-ajukan-btn {
    padding: 10px;
    box-shadow: 0px 2px 50px rgba(93, 95, 97, 0.2);
    border-radius: 6px;
}


.ajukan-btn-container{
    display: flex;
    justify-content: center;


}

@media screen and (min-width: 500px) {
.izin-ajukan-btn {
    max-width: 480px !important;
}

    
}

/* =============== AJUKAN CUTI  =============== */

.cuti-ajukan-container {
    margin-top: 120px;
}

/* =============== GAJI  =============== */
.gaji-after-title {
    margin-top: 45px;
}

.gaji-my-card-group {
    margin-top: 150px;
    margin-bottom: 100px;
}

.gaji-text-gray {
    width: 105.97px;
    height: 17px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #bbbbbb;
}

.gaji-text-black {
    width: 152px;
    height: 19px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-top: -3px;
    color: #494746;
}

.gaji-status-pelunasan {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 20px;
    grid-gap: 10px;
    gap: 10px;
    width: 100px;
    height: 27px;
    background: #fbc02a;
    border-radius: 6px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: -3px;
}

.gaji-status-pelunasan-txt {
    width: 73px;
    height: 15px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.tt {
    width: 95%;
}

.rs-picker {
    border: 0.1px solid #c5c6c7;
    border-radius: 3px;
    box-sizing: border-box;
}

.rs-stack {
    font-size: 14px !important;
}

.w-full {
    width: 100%;
}

.text-field-modifier-time {
    height: 40px !important;
    background: #fbfbfb !important;
    border: 1px solid #e6e9ed !important;
    border-radius: 5px !important;
    display: flex !important;
    justify-content: space-between !important;
    padding: 10px 10px !important;
}

.text-field-modifier-multiline {
    padding: 10px 16px;
    grid-gap: 10px !important;
    gap: 10px !important;
    width: 100%;
    height: 100%;
    background: #fbfbfb !important;
    border-radius: 5px !important;
    padding: 5px 10px !important;

    border: 1px solid #e6e9ed !important;
}

.pengajuan-lembur p {
    margin-bottom: 10px;
}

.text-bold {
    font-weight: 600;
}
.text-field-modifier-long {
    height: 40px !important;
    background: #fbfbfb !important;
    border: 1px solid #e6e9ed !important;
    border-radius: 5px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    padding: 10px 10px !important;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.justify-between {
    justify-content: space-between;
}

.title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline: 15px;
    max-width: 500px !important;
    margin: 0 auto !important;
}


.z-100 {
    z-index: 200 !important;
}

.absen-input-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    /* light blue */

    background: #ebf2fc;
    /* Blue */

    border: 1px solid var(--primary);
    border-radius: 8px;

    width: 72px;
    height: 52.5px;
    color: var(--primary);
}

.absen-input-btn-inactive {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    pointer-events: none;

    border: 1px solid var(--grey-text-login);
    border-radius: 8px;
    color: var(--grey-text-login);

    width: 72px;
    height: 52.5px;
}

.absen-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1;
}

.absen-input-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    position: absolute;
    top: 50px;
    z-index: 1;
    height: 70px;
}

.absen-dropdown {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 16px;
    grid-gap: 10px;
    gap: 10px;

    width: 45%;
    height: 40px;

    /* light grey */

    background: #f8f8f8;
    /* grey */

    border: 1px solid #cecece;
    border-radius: 6px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.absen-dropdown-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    margin: 0 auto;
    position: relative;
    grid-gap: 8vw;
    gap: 8vw;
    padding: 30px 16px 24px;
}




.keluar-click {
    -webkit-filter: invert(27%) sepia(1%) saturate(0%) hue-rotate(166deg)
        brightness(101%) contrast(84%);
            filter: invert(27%) sepia(1%) saturate(0%) hue-rotate(166deg)
        brightness(101%) contrast(84%);
}

.input-absensi-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-self: flex-end;

    position: absolute;
    z-index: 10;
    width: 100%;
    height: 20vh;
    bottom: 0px;
    margin-bottom: 0;
    grid-gap: 20px;
    gap: 20px;

    background: white;
}

.absensi-footer-bottom {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90%;
    grid-gap: 15px;
    gap: 15px;
}
.input-absensi-footer-Button Button {
    margin-bottom: 0px !important;
}

.input-absensi-footer-Button {
    margin-bottom: 0px !important;
    width: 90%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.absensi-input-card h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15.7025px;
    line-height: 19px;
    margin-right: 0px;
    margin-bottom: 5px;

    /* identical to box height */

    /* Black */

    color: #0a0a0a;
}

.absensi-input-card h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    /* identical to box height */

    margin-right: 0px;
    margin-bottom: -10px;

    /* new */

    color: #a3a3a3;
}

.absensi-input-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--light-grey);
    padding-inline: 15px;
    grid-gap: 15px;
    gap: 15px;
}

.input-absensi-button {
    height: 35px;
    width: 35px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--primary);
}

.input-absensi-button-container {
    width: 50px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* Grey - 100 */

    color: #5d5f61;
}

.input-absensi-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;

    text-align: center;
}

.button-absen-masuk {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    ont-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #ffffff;

    width: 90%;

    height: 45px;
    border: none;

    /* Blue */

    background: var(--primary);
    border-radius: 6px;

    /* Inside auto layout */
}

.formbutton2 {
    display: flex;
    align-items: center;
    align-self: flex-end;
    margin-top: 20px;
    margin-right: 50px;
    justify-content: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 89%;

    background: #95e1d3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 17px;
    width: 140px;
    height: 40px;
    /* or 21px */

    color: #28385e;
}

.formbutton2-disable {
    display: flex;
    align-items: center;
    align-self: flex-end;
    margin-top: 20px;
    margin-right: 50px;
    justify-content: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 89%;

    background: #8c8c8c;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 17px;
    width: 140px;
    height: 40px;
    border: none;

    color: #28385e;
}

.formbutton2:hover {
    cursor: pointer;
    background: #28385e;
    color: #95e1d3;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.question_container {
    display: flex;
    /* border: 3px red solid; */
    width: 800px;
    padding: 0px 15px;
    padding-block: 30px;
    padding-inline: 20px;
    border-radius: 25px;
    padding-left: 40px;

    background: var(--primary);
}

.map_container {
    height: 300px;
    width: 300px;
}

.take-picture-button {
    position: absolute;
    bottom: 40px !important;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 100 !important;
}

.take-picture-button Button {
    width: 80px !important;
    height: 80px !important;
    border-radius: 100% !important;
    background: var(--primary) !important;
    z-index: 100 !important;
}

.change-camera-button {
    position: absolute;
    bottom: 50px !important;
    right: 40px;
    z-index: 100 !important;
}

.change-camera-button Button {
    width: 60px !important;
    height: 60px !important;
    border-radius: 100% !important;
    background: #d9d9d962 !important;
    z-index: 100 !important;
}

.change-camera-button Button img {
    width: 30px !important;
}

.change-camera-button-retake {
    position: absolute;
    bottom: 170px !important;
    right: 20px;
    z-index: 100 !important;
}

.change-camera-button-retake Button {
    width: 60px !important;
    height: 60px !important;
    border-radius: 100% !important;
    background: #d9d9d962 !important;
    z-index: 100 !important;
}

.change-camera-button-retake Button img {
    width: 30px !important;
}

.locate-button {
    position: absolute;
    bottom: 170px !important;
    right: 10px;
    z-index: 100 !important;
    background: #ffffff;
    border-radius: 6px;
}

.locate-button Button {
    width: 20px !important;
    height: 60px !important;
    background: #ffffff;
    border-radius: 6px;
    z-index: 100 !important;
}
.locate-button Button img {
    width: 25px !important;
}

.barcode-scanner video {
    height: 75vh !important;
    object-fit: cover !important;
}

.qr-background {
    background: #00000078;
    border-radius: 6px;
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 3;
    -webkit-clip-path: polygon(
        0% 0%,
        0% 100%,
        15% 100%,
        15% 25%,
        85% 25%,
        85% 65%,
        15% 65%,
        15% 100%,
        100% 100%,
        100% 0%
    );
    clip-path: polygon(
        0% 0%,
        0% 100%,
        15% 100%,
        15% 25%,
        85% 25%,
        85% 65%,
        15% 65%,
        15% 100%,
        100% 100%,
        100% 0%
    );
    top: 0px;
    text-align: center;
    color: white;

    padding: 20px;
}

.qr-background h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 89%;
    color: #ffffff;
    margin-top: 16vh;
}

.qr-background h5 {
    font-weight: 400;
    font-size: 20px;
    line-height: 89%;
    color: #ffffff;
    margin-top: 46vh;
}

.intersect1 {
    position: absolute;
    z-index: 5;
    rotate: 180deg;
    top: calc(25% - 2px);
    left: calc(15% - 2px);
}

.intersect2 {
    position: absolute;
    z-index: 5;
    rotate: 270deg;

    top: calc(25% - 2px);

    right: calc(15% - 2px);
}
.intersect3 {
    position: absolute;
    z-index: 5;
    bottom: calc(35% - 2px);
    right: calc(15% - 2px);
}
.intersect4 {
    position: absolute;
    z-index: 5;
    rotate: 90deg;

    bottom: calc(35% - 2px);
    left: calc(15% - 2px);
}

.lembur-button Button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    width: 87%;
    margin: 0 auto;
    margin-top: 20px;
    /* Blue */

    color: #ffffff;
    border-radius: 10px;
    background: #00acee;
    padding: 13px;
}

.starter-margin{
    margin-top: 10px;
}
.btn-simpan{
    background-color:#00acee;
}

.badge-position{
    right: 140px;
    bottom: -10px;
} 

.starter-margin{
    margin-top: 100px;
}
