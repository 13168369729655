.absen-input-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    /* light blue */

    background: #ebf2fc;
    /* Blue */

    border: 1px solid var(--primary);
    border-radius: 8px;

    width: 72px;
    height: 52.5px;
    color: var(--primary);
}

.absen-input-btn-inactive {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    pointer-events: none;

    border: 1px solid var(--grey-text-login);
    border-radius: 8px;
    color: var(--grey-text-login);

    width: 72px;
    height: 52.5px;
}

.absen-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.absen-input-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    position: absolute;
    top: 50px;
    z-index: 1;
    height: 70px;
}

.absen-dropdown {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 16px;
    gap: 10px;

    width: 45%;
    height: 40px;

    /* light grey */

    background: #f8f8f8;
    /* grey */

    border: 1px solid #cecece;
    border-radius: 6px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.absen-dropdown-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    margin: 0 auto;
    position: relative;
    gap: 8vw;
    padding: 30px 16px 24px;
}




.keluar-click {
    filter: invert(27%) sepia(1%) saturate(0%) hue-rotate(166deg)
        brightness(101%) contrast(84%);
}
